import {
  withValidation,
  assert,
  reportError,
  composeSDKFactories,
  registerCorvidEvent,
  isValidMediaItemUri,
  mediaDataType,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import { IVideoPlayerSDKFactory } from '../VideoPlayer.types';
import { createMediaItemUri } from '../../../core/corvid/media/mediaItemUtils';
import { isValidMediaSrc } from '../../../core/corvid/media/mediaSrcHandler';
import {
  getFullMediaData,
  getMediaDataFromSrc,
  getIdFromUrl,
} from '../../../core/corvid/media/backgroundUtils';

import {
  createElementPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';

const _videoPlayerSDKFactory: IVideoPlayerSDKFactory = api => {
  const {
    setProps,
    props,
    createSdkState,
    compRef,
    metaData,
    registerEvent,
  } = api;
  const [state, setState] = createSdkState({
    src: '',
    currentTime: 0,
    volume: props.volume || 0,
    isMuted: Boolean(props.isMuted),
  });

  const videoUrlToStaticVideoURL = (mediaItem: {
    src: string;
    width?: number;
    height?: number;
    uri?: string;
    title?: string;
  }) => {
    const { src, width, height, uri, title } = mediaItem;
    const mediaId = getIdFromUrl(src);
    const mediaItemUri = createMediaItemUri({
      mediaId,
      width,
      height,
      title,
      posterId: uri,
      type: 'video',
    });
    return mediaItemUri.item || src;
  };

  registerEvent(
    'onStateUpdated',
    (value: { currentTime: number; isMuted: boolean; volume: number }) =>
      setState(value),
  );

  return {
    get title() {
      return props.playableConfig.title || '';
    },

    set title(title: string) {
      setProps({
        playableConfig: {
          ...props.playableConfig,
          title,
        },
      });
    },

    get isMuted() {
      return Boolean(state.isMuted);
    },

    get isPlaying() {
      return Boolean(props.isPlaying);
    },

    get description() {
      return props.playableConfig.description || '';
    },

    set description(description: string) {
      setProps({
        playableConfig: {
          ...props.playableConfig,
          description,
        },
      });
    },

    get currentTime() {
      return state.currentTime || 0;
    },

    get duration() {
      return props.duration || 0;
    },

    get volume() {
      return state.volume;
    },

    set volume(volume: number) {
      compRef.setVolume(volume);
      setState({ volume });
    },

    get src() {
      let src = props.src;
      if (Array.isArray(props.src)) {
        src = props.src[0];
      }
      if (props.playableConfig.poster) {
        const { width, height, uri } = props.playableConfig.poster;
        if (!state.src) {
          return videoUrlToStaticVideoURL({ src, width, height, uri });
        }
      }
      return state.src || src;
    },

    set src(value: string) {
      const src = assert.isNil(value) ? '' : value;

      if (isValidMediaSrc(src, 'video')) {
        const mediaData = getMediaDataFromSrc(value);
        if (!mediaData) {
          return;
        }
        if (mediaData.posterImageRef) {
          const { width, height, uri } = mediaData.posterImageRef;
          const title = mediaData.name;
          setState({
            src: videoUrlToStaticVideoURL({
              src,
              width,
              height,
              uri,
              title,
            }),
          });
        }
        if (mediaData.type === 'WixVideo') {
          getFullMediaData(mediaData, fullMediaRefData => {
            if (!fullMediaRefData) {
              setState({ src: props.src });
              return;
            }

            setProps({
              playableConfig: {
                ...props.playableConfig,
                poster: {
                  ...props.playableConfig.poster,
                  ...fullMediaRefData.mediaObject.posterImageRef,
                },
              },
              src: `https://video.wixstatic.com/${fullMediaRefData.mediaObject.qualities[0].url}`, // TODO staticVideoUrl
            });
          });
        }
      } else if (isValidMediaSrc(src, 'image')) {
        // This checks whether url is an external link (http/https)
        // TODO: create proper matchers to make this readable
        setState({ src: value });

        setProps({
          src: value,
          // When getting a custom extenal link, reset `playableConfig`.
          playableConfig: {},
        });
      } else {
        reportError(
          `The "src" property cannot be set to "${value}". It must be a valid URL starting with "http://", "https://", or a valid video URL starting with "wix:video://".`,
        );
      }
    },

    get poster() {
      const { width, height, uri } = props.playableConfig!.poster!;
      const { title } = props.playableConfig;

      const mediaItemUri = createMediaItemUri({
        mediaId: uri,
        width,
        height,
        title,
        type: 'image',
      });

      return mediaItemUri.item || '';
    },

    set poster(uri: string) {
      let poster: Partial<mediaDataType> | null = props.playableConfig?.poster
        ? props.playableConfig?.poster
        : {};

      if (isValidMediaItemUri(uri, 'image')) {
        poster = getMediaDataFromSrc(uri);
        uri = poster?.mediaId || uri;
      }

      setProps({
        playableConfig: {
          ...props.playableConfig,
          ...(poster ? { title: poster.title } : {}),
          poster: {
            ...poster,
            uri,
          },
        },
      });
    },

    play() {
      return compRef.play();
    },

    pause() {
      return compRef.pause();
    },

    stop() {
      return compRef.stop();
    },

    togglePlay() {
      return compRef.togglePlay();
    },

    mute() {
      setState({ isMuted: true });
      return compRef.mute();
    },

    unmute() {
      setState({ isMuted: false });
      return compRef.unmute();
    },

    seek(timePoint: number) {
      return compRef.seek(timePoint);
    },

    onPlay: handler => registerCorvidEvent('onPlay', api, handler),

    onPause: handler => registerCorvidEvent('onPause', api, handler),

    onEnded: handler => registerCorvidEvent('onEnded', api, handler),

    onProgress: handler => registerCorvidEvent('onProgress', api, handler),

    toJSON() {
      return {
        ...toJSONBase(metaData),
        title: props.playableConfig.title || '',
        isPlaying: Boolean(props.isPlaying),
        currentTime: state.currentTime || 0,
        duration: props.duration || 0,
        volume: state.volume || 0,
        isMuted: Boolean(state.isMuted),
      };
    },
  };
};

const videoPlayerSDKFactory: IVideoPlayerSDKFactory = withValidation(
  _videoPlayerSDKFactory,
  {
    type: ['object'],
    properties: {
      title: { type: ['string', 'nil'], warnIfNil: true },
      description: { type: ['string', 'nil'], warnIfNil: true },
      volume: {
        type: ['number', 'nil'],
        minimum: 0,
        maximum: 100,
        warnIfNil: true,
      },
      poster: { type: ['string', 'nil'], warnIfNil: true },
      src: {
        type: ['string', 'nil'],
        warnIfNil: true,
      },
    },
  },
);
const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk: IVideoPlayerSDKFactory = composeSDKFactories(
  elementPropsSDKFactory,
  videoPlayerSDKFactory,
);

export default createComponentSDKModel(sdk);
